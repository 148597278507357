import React from "react";
import { getCreateAccountPath, getSignInPath, isSignedIn } from "../../utils/util";
import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import { useTranslation } from "react-i18next";

const QuizBadgeResult = ({
  badgePageUrl,
  currentBadge,
  educationPageUrl,
  handleReset,
  passed,
  passingScore,
  title,
  totalPassedQuestions,
  totalQuestions,
  totalScore,
  userBadgeMonth,
  userBadgeYear,
}) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  return (
    <>
      {isSignedIn() && (
        <div className="container-quiz-result">
          <div className="quiz-result-sheet">
            <div className="content-result">
              <h3>
                {passed
                  ? `${t("congratulations")} 🎉`
                  : `${t("failed-title")} \u{1F61E}`}{" "}
              </h3>
              <div className="text-quiz-result">
                <p>
                  {passed
                    ? t("congratulations-text", { courseTitle: title })
                    : t("failed-message", { passingScore: passingScore })}
                </p>
              </div>
            </div>

            <div className="content-score">
              <p>{t("your-score")} </p>
              <div className="ponctuation">
                <span className={"d-flex"}>
                  <span className={"d-flex"}>
                    <h3 className={"m-auto"}>
                      {t("score-break-down", {
                        totalCorrect: totalPassedQuestions,
                        totalCount: totalQuestions,
                        score: totalScore,
                      })}
                    </h3>
                  </span>
                  {passed && (
                    <span className="pass-tag d-flex">
                      <span className={"m-auto"}>
                        <span className="circle">
                          <span>✔</span>
                        </span>
                        <span>{t("pass")}</span>
                      </span>
                    </span>
                  )}
                </span>
              </div>
              {!passed && (
                <div className="quiz-retake my-2">
                  <ClayButton onClick={handleReset}>
                    {t("try-again")}

                    <span className="inline-item inline-item-before mx-2">
                      <ClayIcon symbol="reload" />
                    </span>
                  </ClayButton>
                </div>
              )}
            </div>
          </div>
          {passed && (
            <div className="container-badges">
              <div className="badges-content">
                {currentBadge && (
                  <div>
                    <img
                      alt={currentBadge.title}
                      src={currentBadge.badge.link.href}
                    />
                  </div>
                )}

                <div>
                  <a className="button-badges-view" href={badgePageUrl}>
                    {t("see-my-badges")} →
                  </a>
                </div>
                <div>
                  {userBadgeYear && userBadgeMonth ? (
                    <a
                      className="button-linkedin-add"
                      href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${encodeURIComponent(
                        title
                      )}&organizationId=83609&issueYear=${userBadgeYear}&issueMonth=${userBadgeMonth}&expirationYear=&expirationMonth=&certUrl=&certId=`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Add to LinkedIn
                    </a>
                  ) : (
                    <p>Loading badge information...</p>
                  )}
                </div>
              </div>
              <a href={educationPageUrl}>
                {t("return-to-education-home-page")}
              </a>
            </div>
          )}
        </div>
      )}

      {!isSignedIn() && (
        <div className="container-quiz-result">
          <div className="quiz-result-sheet">
            <div className="content-result">
              <h3>
                {passed
                  ? `${t("congratulations")} 🎉`
                  : `${t("failed-title")} \u{1F61E}`}{" "}
              </h3>
              <div className="text-quiz-result">
                <p>
                  {passed ? (
                    <>
                      {t("you-passed")}{" "}
                      <a href={getSignInPath()}>{t("sign-in")}</a>{" "}
                      {t("retake-exam")}
                    </>
                  ) : (
                    <>
                      {t("failed-message-unassigned", {
                        passingScore: passingScore,
                      })}{" "}
                      <a href={getSignInPath()}>{t("log-in")}</a> {t("or")}{" "}
                      <a href={getCreateAccountPath()}>{t("create-account")}</a>{" "}
                      {t("failed-unassigned")}
                    </>
                  )}
                </p>
              </div>
            </div>

            <div className="content-score">
              <p>{t("your-score")} </p>
              <div className="ponctuation">
                <span className={"d-flex"}>
                  <span className={"d-flex"}>
                    <h3 className={"m-auto"}>
                      {t("score-break-down", {
                        totalCorrect: totalPassedQuestions,
                        totalCount: totalQuestions,
                        score: totalScore,
                      })}
                    </h3>
                  </span>
                  {passed && (
                    <span className="pass-tag d-flex">
                      <span className={"m-auto"}>
                        <span className="circle">
                          <span>✔</span>
                        </span>
                        <span>{t("pass")}</span>
                      </span>
                    </span>
                  )}
                </span>
              </div>
              {!passed && (
                <div className="quiz-retake my-2">
                  <ClayButton onClick={handleReset}>
                    {t("try-again")}

                    <span className="inline-item inline-item-before mx-2">
                      <ClayIcon symbol="reload" />
                    </span>
                  </ClayButton>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuizBadgeResult;
