import {getServerUrl, getSpringOAuthToken, oAuthRequest,request} from "../utils/request";
import {config} from "../utils/constants";
import {getCurrentSiteId} from "../utils/util";


export const loadExerciseQuestions = (exerciseId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.exerciseEndPoint}${exerciseId}/questions?pageSize=100`,
        method:'get'
    });
}

export const getQuestions = async (exerciseId) => {
    try {
        const token = await getSpringOAuthToken();

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        const response = await fetch(
            `https://liferaylearnetcspringboot-exte5a2learn-extuat.lfr.cloud/learn/${exerciseId}/questions`,
            requestOptions
        );

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error fetching questions:", error);
        throw error; 
    }
};

export const postQuizResult = async (exerciseId, answersData) => {
    try {
      const token = await getSpringOAuthToken();
  
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      myHeaders.append("Authorization", `Bearer ${token}`);
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(answersData),
        redirect: "follow"
      };
  
      const response = await fetch(
        `https://liferaylearnetcspringboot-exte5a2learn-extuat.lfr.cloud/learn/${exerciseId}/result`,
        requestOptions
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
  
      return result;
    } catch (error) {
      console.error("Error posting quiz result:", error);
      throw error;
    }
};

export const getQuiz = (exerciseId) =>{

    let serviceBaseAddress = getServerUrl();

    return oAuthRequest({
        url:`${serviceBaseAddress}${config.exerciseEndPoint}${exerciseId}`,
        method:'get'
    });

}

export const postExerciseResult = async (exerciseId,answersData) =>{

    let serviceBaseAddress = getServerUrl();

    return await request({
        url:`${serviceBaseAddress}${config.exerciseEndPoint}${exerciseId}/result`,
        method:'post',
        data:{
            answers:answersData,
            scopeId : getCurrentSiteId()
        }
    });
}

