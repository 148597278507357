/* global Liferay */
import React, { useEffect, useState } from "react";
import { getDPTAssetId } from "../../utils/util";
import { updateCompletedAssets } from "../../services/enrollment";
import KnowldgeCheckResult from "./KnowldgeCheckResult";
import QuizBadgeResult from "./QuizBadgeResult";

const QuizResultSheet = ({
  isEnrolled,
  currentQuizzId,
  userEnrollmentId,
  completedAssetIds,
  scoreSheetOverall,
  badgePageUrl,
  educationPageUrl,
  handleReset,
  isKnowledgeCheck,
  questions,
  currentBadge,
  userBadgeYear,
  userBadgeMonth,
}) => {
  const {
    passed,
    passingScore,
    title,
    totalPassedQuestions,
    totalQuestions,
  } = scoreSheetOverall;

  const [moduleInfo, setModuleInfo] = useState({});

  if (passed) {
    const handleUpdateCompletedAssets = async () => {
      const isQuizCompleted = completedAssetIds?.includes(currentQuizzId);

      if (isEnrolled && !isQuizCompleted) {
        await updateCompletedAssets(
          userEnrollmentId,
          completedAssetIds,
          currentQuizzId
        );
      }
    };

    handleUpdateCompletedAssets();
  }

  useEffect(() => {
    if (isKnowledgeCheck) {
      const fetchData = async () => {
        Liferay.Util.fetch(
          `/o/c/modules/scopes/${Liferay.ThemeDisplay.getScopeGroupId()}?filter=quiz%2Fid%20eq%20%27${getDPTAssetId()}%27`
        )
          .then((response) => response.json())
          .then((response) => {
            setModuleInfo({
              position: response.items[0].position,
              title: response.items[0].title,
            });
          });
      };
      fetchData().catch(console.error);
    }
  }, [isKnowledgeCheck]);

  return (
    <>
      {scoreSheetOverall && !isKnowledgeCheck && (
        <>
          <QuizBadgeResult
            badgePageUrl={badgePageUrl}
            currentBadge={currentBadge}
            educationPageUrl={educationPageUrl}
            handleReset={handleReset}
            passed={passed}
            passingScore={passingScore}
            title={title}
            totalPassedQuestions={totalPassedQuestions}
            totalQuestions={totalPassedQuestions}
            totalScore={totalPassedQuestions}
            userBadgeMonth={userBadgeMonth}
            userBadgeYear={userBadgeYear}
          />
        </>
      )}

      {isKnowledgeCheck && (
        <KnowldgeCheckResult
          questions={questions}
          scoreSheetOverall={scoreSheetOverall}
          totalPassedQuestions={totalPassedQuestions}
          totalQuestions={totalQuestions}
        />
      )}
    </>
  );
};

export default QuizResultSheet;
