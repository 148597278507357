import React from "react";
import { useTranslation } from "react-i18next";

const KnowldgeCheckResult = ({
  questions,
  scoreSheetOverall,
  totalPassedQuestions,
  totalQuestions,
}) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  
  return (
    <>
      <div className="card-kc-finish">
        <h2>{t("knowledge-check-results")}</h2>

        <li>
          <div className="info-card-kc">
            <div className="completed-message">
              {t("you-completed-knowledge-check")}
            </div>

            <div className="score-content">
              <p>{t("your-score")}</p>
              <div>
                <h4>{totalPassedQuestions}</h4>{" "}
                <p>
                  <b>{t("of")}</b>
                </p>{" "}
                <h4>{totalQuestions}</h4>
              </div>
            </div>
          </div>
        </li>
      </div>

      <div className="container-questions">
        <h3>{t("questions")}</h3>

        {questions.map((question) => {
          const scoreData =
            scoreSheetOverall.scoreSheet.find(
              (q) => q.questionId === question.id
            ) || {};

          return (
            <li className="answers-content" key={question.id}>
              {question.questionType.key === "selectSingleChoice" && (
                <div>
                  <div className="title-question">
                    <div
                      className={`circle-title ${
                        scoreData.achievedScore > 0
                          ? "circle-right"
                          : "circle-wrong"
                      }`}
                    >
                      {question.position}
                    </div>
                    <p>{question.question}</p>
                  </div>

                  <div className="answer-container">
                    {question.quizAnswers.map((answer) => {
                      const answerData =
                        scoreData.questionsAnswers?.find(
                          (a) => a.id === answer.id
                        ) || {};

                      return (
                        <li key={answer.id}>
                          <div className="all-questions">
                            <input
                              className={
                                scoreData.selectedAnswer === answer.id
                                  ? answerData.score > 0
                                    ? "circle-question-right"
                                    : "circle-question-wrong"
                                  : "circle-question"
                              }
                              disabled
                            />
                            <p>{answer.answer}</p>
                            {scoreData.selectedAnswer === answer.id &&
                              answerData.score === 0 && (
                                <p className="wrong-answer">
                                  {t("wrong-answer")}
                                </p>
                              )}
                            {scoreData.selectedAnswer !== answer.id &&
                              answerData.score > 0 && (
                                <p className="correct-answer">
                                  {t("correct-answer")}
                                </p>
                              )}
                          </div>
                        </li>
                      );
                    })}
                  </div>
                </div>
              )}

              {question.questionType.key === "selectMultipleChoice" && (
                <>
                  <div className="title-question">
                    <div
                      className={`circle-title ${
                        scoreData.achievedScore > 0
                          ? "circle-right"
                          : "circle-wrong"
                      }`}
                    >
                      {question.position}
                    </div>
                    <p>{question.question}</p>
                  </div>

                  <div className="answer-container">
                    {question.quizAnswers.map((answer) => {
                      const answerData =
                        scoreData.questionsAnswers?.find(
                          (a) => a.id === answer.id
                        ) || {};

                      const isSelected =
                        scoreData.selectedAnswer &&
                        typeof scoreData.selectedAnswer === "object"
                          ? scoreData.selectedAnswer[answer.id]
                          : false;

                      return (
                        <li key={answer.id}>
                          <div className="all-questions">
                            <div
                              className={
                                isSelected
                                  ? answerData.score > 0
                                    ? "square-check-correct"
                                    : "square-check-wrong"
                                  : "square-check"
                              }
                            ></div>
                            <p>{answer.answer}</p>

                            {isSelected && answerData.score === 0 && (
                              <p className="wrong-answer">
                                {t("wrong-answer")}
                              </p>
                            )}

                            {!isSelected && answerData.score > 0 && (
                              <p className="correct-answer">
                                {t("correct-answer")}
                              </p>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </div>
                </>
              )}
            </li>
          );
        })}
      </div>
    </>
  );
};

export default KnowldgeCheckResult;
